import React from "react"
import * as styles from "./index.module.scss"
import Seo from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"
import { BiPhoneCall } from "react-icons/bi"
import { SiMinutemailer } from "react-icons/si"
import { HiOutlineLocationMarker } from "react-icons/hi"
import contact from "../../config/contact"

const iFrameUrl = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2703.570056629425!2d7.866875116262386!3d47.34226741419269!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4790314cba465a2f%3A0xbc21b8bd64bf99b6!2sBahnhofpl.%201b%2C%204612%20Wangen%20bei%20Olten%2C%20Switzerland!5e0!3m2!1sen!2slk!4v1636297239853!5m2!1sen!2slk"

const ContactUsPage = () => {
  return (
    <>
      <Seo title="Kontakt" />
      <Breadcrumbs title={"Kontakt"} />

      <section>
        <iframe
          src={iFrameUrl}
          frameBorder="0"
          title={"map"}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
          className={styles.map}
        />
      </section>

      <div className="container text-center mt-5">
        <div className="row">
          <div className="col-sm-6 col-lg-4">
            <div className={styles.round_icon}>
              <HiOutlineLocationMarker />
            </div>
            <h4 className={"text-theme"}>Unsere Adresse</h4>
            <p>
              Bahnhofplatz 1B,
              <br />
              Wangen bei Olten, 4612,
              <br />
              Schweiz.
            </p>
          </div>

          <div className="col-sm-6 col-lg-4">
            <div className={styles.round_icon}>
              <SiMinutemailer />
            </div>
            <h4 className={"text-theme"}>Fragen? Schreiben Sie uns</h4>
            <p>
              <a
                href={`mailto:${contact.email}`}
                className={styles.contact_link}
              >
                {contact.email}
              </a>
            </p>
          </div>

          <div className="col-sm-6 col-lg-4">
            <div className={styles.round_icon}>
              <BiPhoneCall />
            </div>
            <h4 className={"text-theme"}>Telefon</h4>
            <p>
              <a
                href={`tel:${contact.phone.link}`}
                className={styles.contact_link}
              >
                {contact.phone.display}
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUsPage
